// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg2 from "../images/blog/img-6.jpg";
import blogSingleImg3 from "../images/blog/img-5.jpg";



const blogs = [ 
    {
        id: '1',
        title: 'Our expert service provider make your home pest',
        slug: 'Our-expert-service-provider-make-your-home-pest',
        screens: blogImg1,
        description: 'Raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to',
        author: 'Jhone Miche',
        authorTitle:'Sineor Consultant',
        create_at: 'Feb 16, 2024',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'There are tens of cleaning companies listed',
        slug: 'There-are-tens-of-cleaning-companies-listed',
        screens: blogImg2,
        description: 'Raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to',
        author: 'Drain ship',
        authorTitle:'Creative Director',
        create_at: 'March 17, 2024',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'Reputation of the company is an aspect one should',
        slug: 'Reputation-of-the-company-is-an-aspect-one-should',
        screens: blogImg3,
        description: 'Raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to',
        author: 'Michel niew',
        authorTitle:'Art Director',
        create_at: 'Jan 18, 2024',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
];
export default blogs;