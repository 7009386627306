import pimg from '../images/latest-projects/img-1.jpg'
import pimg2 from '../images/latest-projects/img-2.jpg'
import pimg3 from '../images/latest-projects/img-3.jpg'
import pimg4 from '../images/latest-projects/img-4.jpg'
import pimg5 from '../images/latest-projects/img-5.jpg'


const Projects = [
    {
        Id: '1',
        pImg:pimg,
        subTitle:'Muriddban, USA',
        title: 'Carpet',
        slug:'Carpet',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '2',
        pImg:pimg2,
        subTitle:'Cleaning, Renovation',
        title: 'Seasonal',
        slug:'Seasonal',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '3',
        pImg:pimg3,
        subTitle:'Muriddban, USA',
        title: 'Express',
        slug:'Express',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '4',
        pImg:pimg4,
        subTitle:'Cleaning, Renovation',
        title: 'Kitchen',
        slug:'Kitchen',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '5',
        pImg:pimg5,
        subTitle:'Muriddban, USA',
        title: 'Bathroom',
        slug:'Bathroom',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '6',
        pImg:pimg2,
        subTitle:'Cleaning, Renovation',
        title: 'Seasonal',
        slug:'Seasonal',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
]
export default Projects;