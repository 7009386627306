import timg1 from '../images/team/img-1.jpg'
import timg2 from '../images/team/img-2.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/img-4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Abdal Adil',
        title:'Managing Director',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Michel Wilam',
        title:'Site Manager',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Shawn Bella',
        title:'House cleaner',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Jhain michel',
        title:'Cheif Officer',
    }
]

export default Teams;