import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'


const Services = [
    {
        Id: '1',
        sImg:simg,
        sTitle:'Home Cleaning',
        slug:'Home-Cleaning',
        description:'Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-home',
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle:'Office Cleaning',
        slug:'Office-Cleaning',
        description:'Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-archive',
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Window Cleaning',
        slug:'Window-Cleaning',
        description:'Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-window',
    },
    {
        Id: '4',
        sImg:simg4,
        sTitle: 'Carpet Cleaning',
        slug:'Carpet-Cleaning',
        description:'Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-carpet',
    }
]    

export default Services;